import { LayoutApp } from '@frontend/domains/plus/app'
import React, { ReactNode } from 'react'
import dynamic from 'next/dynamic'

const Dashboard = dynamic(() => import('@frontend/domains/plus/dashboard').then((module) => module.Dashboard))

const DashboardPage = () => <Dashboard />

DashboardPage.getLayout = (page: ReactNode) => <LayoutApp>{page}</LayoutApp>

export default DashboardPage
